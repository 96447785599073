/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Grid, Box, Button } from "@material-ui/core";
import styles from "./Enrollment.module.scss";
import { useGlobalUserData } from "../lib/globalUserData";
import { axiosInstance } from "../services/customaxios";
import LoaderFis from "../components/Loader/LoaderFIS";
import { useTranslation } from "../contexts/Localization";
import { FISFlow } from "../utils/fetchFIS";
import parseJWT from "../utils/parseJWT";
import { getMessage } from "../utils/status";

// import { reactStringReplace } from '../utils/reactStringReplace';
const Terms = () => {
  const { changeStep, userData, updateUserData, showGlobalAlert } =
    useGlobalUserData();
  const { policy } = userData;
  const { apiLanguage, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const onCheckBoxChange = () => {
    setIsChecked(!isChecked);
  };

  const onButtonClick = async ({ cancel }) => {
    const payLoad = {
      step: "policy",
      data: {
        date: policy.date,
        read: cancel ? false : isChecked,
        policyText: policy.policyText,
        signature: policy.signature,
        pageTitle: policy.pageTitle,
        userLocation: null,
        skip: false,
        cancel,
        cookie: userData.cookie,
      },
    };
    setLoading(true);
    await axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/policy/agreement`,
        payLoad,
        {
          withCredentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: "idm",
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        if (res.data.status.code !== 200) {
          setLoading(false);
          showGlobalAlert({
            type: "error",
            message: getMessage(res.data.status.code).body,
          });
        } else {
          if (
            res.data.result[0].nextStep &&
            res.data.result[0].nextStep.id === "policy"
          ) {
            setLoading(false);
            setIsChecked(false);
            console.log("res.data.result[0].nextStep.params.policyText");
            console.log(res.data.result[0].nextStep.params.policyText);
            updateUserData({
              ...userData,
              policy: {
                pageTitle: res.data.result[0].nextStep.params.pageTitle,
                policyText: res.data.result[0].nextStep.params.policyText,
                signature: res.data.result[0].nextStep.params.signature,
                date: res.data.result[0].nextStep.params.date,
              },
            });
          } else if (
            res.data.result[0].nextStep &&
            res.data.result[0].nextStep.id === "username"
          ) {
            setLoading(false);
            changeStep("fislogin");
          } else {
            axiosInstance
              .post(
                `${process.env.REACT_APP_IDM_API}authentication/idptoken`,
                {
                  cookie: userData.cookie,
                  replaceToken: true,
                },
                {
                  withCredentials: "include",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                    appid: "idm",
                    language: apiLanguage,
                  },
                }
              )
              .then(async (resp) => {
                const { id_token } = resp.data.result[0];
                const accessToken = id_token.substring(
                  0,
                  id_token.lastIndexOf(".")
                );
                axiosInstance
                  .post(
                    `${process.env.REACT_APP_IDM_API}authentication/users/getaccesstoken`,
                    {
                      cookie: res.data.result[0].cookies[0],
                      accessTokenKey: parseJWT(accessToken)?.accessToken,
                    },
                    {
                      withCredentials: "include",
                      // crossDomain: true,
                      headers: {
                        appid: "idm",
                        language: apiLanguage,
                        Accept: "application/json",
                        "x-api-key":
                          process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                        "Content-Type": "application/json",
                      },
                    }
                  )
                  .then(async (respn) => FISFlow(respn,userData,changeStep,showGlobalAlert))
                  .catch((err) => {
                    console.error(err);
                    setLoading(false);
                    updateUserData({
                      ...userData,
                      password: "",
                      userpassword: "",
                    });
                    showGlobalAlert({
                      type: "error",
                      message: getMessage("defaultCode").body,
                    });
                    changeStep("fislogin");
                  });
              })
              .catch((err) => {
                setLoading(false);
                updateUserData({
                  ...userData,
                  userpassword: "",
                  password: "",
                });
                console.error(err);
              });
          }
        }
      })
      .catch((err) => {
        updateUserData({
          ...userData,
          userpassword: "",
          password: "",
        });
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      {policy && (
        <Grid container justifyContent="center">
          <Grid item md={4}>
            <Box
              className={styles.container}
              style={{ alignItems: "flex-start" }}
            >
              <Box style={{ fontWeight: "bold" }}>
                {t("Welcome to Oriental Online Banking")}
                {/* {t(
                'Please go over the terms of use and record your consent to continue using the application.'
              )} */}
                {/* <>
          {reactStringReplace(
            'Hey @ian_sinn, @check out @this link https://github.com/iansinnott/ Hope to see @you at #reactconf',
            /@(\w+)/g,
            (dd, i) => (
              <a key={dd + i} href={`https://twitter.com/${dd}`}>
                @{dd}
              </a>
            )
          )}
        </> */}
              </Box>
              <Box className={styles.boxMarginTop}>
                {/* {getPolicy(
                "['Please {go over} our {Online Banking Disclosure} and {Privacy Statement} and record your consent to continue using the application. We recommend that you print a copy for your records.', 'https://google.com', 'https://orientalbank.com/assets/d1/Terms_Conditions_Online_Banking.pdf', 'https://orientalbank.com/en/privacy-policy']"
              )} */}
                {/* {policy &&
                policy?.policyText &&
                getPolicy(JSON.parse(policy?.policyText)[apiLanguage])} */}
                {/* <div
                dangerouslySetInnerHTML={{
                  __html:
                    '<div>Please go over our <a target="_blank" href="https://orientalbank.com/assets/d1/Terms_Conditions_Online_Banking.pdf">Online Banking Disclosure</a> and <a target="_blank" href="https://orientalbank.com/en/privacy-policy">Privacy Statement</a> and record your consent to continue using the application. We recommend that you print a copy for your records.<div>',
                }}
              /> */}
                {/* {policy && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: "<p><p></p><p>Please go over our <a href='https://orientalbank.com/assets/d1/Terms_Conditions_Online_Banking.pdf' target='_blank'>Online Banking Disclosure</a> and <a href='https://orientalbank.com/en/privacy-policy' target='_blank'>Terms and Conditions</a> and record your consent to continue using the application. We recommend that you print a copy for your records </p>".replaceAll("'", '"'),
                  }}
                />
              )} */}
                {policy && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: policy?.policyText[apiLanguage?.toLowerCase()],
                    }}
                  />
                )}
              </Box>
              <Box style={{ marginTop: "10px" }}>
                <input
                  type="checkbox"
                  data-testid="termsCheckBox"
                  id="policy"
                  name="policy"
                  checked={isChecked}
                  onChange={onCheckBoxChange}
                />
                <label for="policy">
                  {/* {t(
                  'I have read, understood and accepted to the terms of use/features and services provided by Digital One Banking application.'
                )} */}
                  {t(
                    "I have read and agree to the terms of the Oriental Online Banking Agreement and Disclosure."
                  )}
                </label>
              </Box>
              <Grid
                container
                spacing={2}
                style={{ marginTop: 20 }}
                className={styles.btn_wrapper_pass}
                justifyContent="space-around"
              >
                <Grid item sm={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => onButtonClick({ cancel: false })}
                    disabled={!isChecked}
                    className={styles.btn_continue_terms}
                  >
                    {t("Continue")}
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    type="button"
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={() => onButtonClick({ cancel: true })}
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      <LoaderFis open={loading} />
    </>
  );
};

export default Terms;
