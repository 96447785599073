/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { axiosInstance } from "../services/customaxios";
import {
  OutlinedInput,
  Grid,
  Button,
  InputAdornment,
  IconButton,
  InputLabel,
  Tooltip,
  Zoom,
  FormControl,
  Box,
  Link,
} from "@material-ui/core";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

import Alert from "../components/Alert/Alert";
import LoaderFis from "../components/Loader/LoaderFIS";
import { useGlobalUserData } from "../lib/globalUserData";
import hideIcon from "../images/eyeIconHide.png";
import showIcon from "../images/eyeIcon.png";
import { useTranslation } from "../contexts/Localization";
import styles from "./MigrationWeb.module.scss";
import FisAlert from "../Enrollment/FisAlert";
import { getMessage } from "../utils/status";
import { enrollTooltipStyles } from "./EnrollTooltipStyles";
import { Requirements } from "../components/Requirements/Requirements";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import warningIconInfo from "../images/warningIconInfo.svg";
import parseJwt from "../utils/parseJWT";
import { FISFlow } from "../utils/fetchFIS";
import { FISWrapper } from "../Enrollment/FISWrapper";
import { getReqArray } from "../utils/requirementArray";

const useStyles = enrollTooltipStyles();

const MigrationChangePassword = () => {
  const classes = useStyles();
  const { changeStep, userData, showGlobalAlert, globalAlert, updateUserData } =
    useGlobalUserData();
  const { t, apiLanguage } = useTranslation();
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    reenterPassword: "",
  });
  const [formErrors, setFormErrors] = useState({
    newPassword: "",
    reenterPassword: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [showHideIcon, setShowHideIcon] = useState({
    oldPassword: VisibilityOffOutlinedIcon,
    newPassword: VisibilityOffOutlinedIcon,
    reenterPassword: VisibilityOffOutlinedIcon,
  });
  const [loading, setLoading] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [reenterPasswordValid, setReenterPasswordValid] = useState(false);

  const [passwordToggle, setPasswordToggle] = useState({
    oldPassword: true,
    newPassword: true,
    reenterPassword: true,
  });

  const reqArray = getReqArray(userData.userid);

  const reenterArray = [
    {
      validator: (val) => val === formData.newPassword,
      text: "Passwords must be the same.",
      reqSatisfied: false,
    },
  ];

  useEffect(() => {
    setFormData({
      ...formData,
      ...userData,
    });
    showGlobalAlert({
      type: "success",
      message: "Please change your password to continue",
    });
    return () => {
      showGlobalAlert({
        type: "",
        message: "",
      });
    };
  }, []);

  const submit = () => {
    setLoading(true);
    setPasswordToggle({
      oldPassword: true,
      newPassword: true,
      reenterPassword: true,
    });
    setShowHideIcon({
      oldPassword: VisibilityOffOutlinedIcon,
      newPassword: VisibilityOffOutlinedIcon,
      reenterPassword: VisibilityOffOutlinedIcon,
    });
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/changepassword`,
        {
          step: "changepassword",
          cookieData: null,
          data: {
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword,
            confirmPassword: formData.reenterPassword,
            cookie: userData.cookie,
          },
        },
        {
          withCredentials: "include",
          headers: {
            appid: "idm",
            language: apiLanguage,
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((res) => {
        if (res.data.status.code === 200) {
          if (userData.flow === "BATCH_AUTH") {
            if (
              res.data.result[0].nextStep &&
              res.data.result[0].nextStep.id === "policy"
            ) {
              updateUserData({
                ...userData,
                cookie: res.data.result[0].cookies[0],
                policy: {
                  pageTitle: res.data.result[0].nextStep.params.pageTitle,
                  policyText: res.data.result[0].nextStep.params.policyText,
                  signature: res.data.result[0].nextStep.params.signature,
                  date: res.data.result[0].nextStep.params.date,
                },
              });
              changeStep("fisterms");
            } else {
              axiosInstance
                .post(
                  `${process.env.REACT_APP_IDM_API}authentication/idptoken`,
                  {
                    cookie: res.data.result[0].cookies[0],
                    replaceToken: true,
                  },
                  {
                    withCredentials: "include",
                    // crossDomain: true,
                    headers: {
                      appid: "idm",
                      language: apiLanguage,
                      Accept: "application/json",
                      "x-api-key":
                        process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                      "Content-Type": "application/json",
                    },
                  }
                )
                .then(async (respData) => {
                  const { id_token } = respData.data.result[0];
                  const accessToken = id_token.substring(
                    0,
                    id_token.lastIndexOf(".")
                  );
                  axiosInstance
                    .post(
                      `${process.env.REACT_APP_IDM_API}authentication/users/getaccesstoken`,
                      {
                        cookie: res.data.result[0].cookies[0],
                        accessTokenKey: parseJwt(accessToken)?.accessToken,
                      },
                      {
                        withCredentials: "include",
                        // crossDomain: true,
                        headers: {
                          appid: "idm",
                          language: apiLanguage,
                          Accept: "application/json",
                          "x-api-key":
                            process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then(async (respn) => FISFlow(respn,userData,changeStep,showGlobalAlert))
                    .catch((err) => {
                      console.error(err);
                      updateUserData({
                        ...userData,
                        password: "",
                        userpassword: "",
                      });
                      showGlobalAlert({
                        type: "error",
                        message: getMessage("defaultCode").body,
                      });
                      changeStep("fislogin");
                    });
                })
                .catch((err) => {
                  console.error(err);
                  setLoading(false);
                  updateUserData({
                    ...userData,
                    userpassword: "",
                    password: "",
                  });
                });
            }
          } else {
            setLoading(false);
            changeStep("migrationchangepasswordsuccess");
          }
        } else {
          setLoading(false);
          showGlobalAlert({
            type: "error",
            message: getMessage(res.data.status.code).body,
          });
        }
      })
      .catch((err) => {
        setFormData({
          ...formData,
          oldPassword: "",
          newPassword: "",
          reenterPassword: "",
        });
        updateUserData({
          ...userData,
          userpassword: "",
          password: "",
        });
        console.error(err);
        setLoading(false);
        showGlobalAlert({
          type: "error",
          message: getMessage("defaultCode").body,
        });
      });
  };

  const passwordIconHandler = (passname, e) => {
    e.preventDefault();
    setPasswordToggle({
      ...passwordToggle,
      [passname]: !passwordToggle[passname],
    });
    const icon =
      showHideIcon[passname] === VisibilityOffOutlinedIcon
        ? VisibilityOutlinedIcon
        : VisibilityOffOutlinedIcon;
    setShowHideIcon({
      ...showHideIcon,
      [passname]: icon,
    });
  };

  useEffect(() => {
    setSubmitDisabled(
      !formData.oldPassword ||
        !formData.newPassword ||
        !formData.reenterPassword ||
        // Object.values(formErrors).some((value) => value !== '') ||
        !reenterPasswordValid ||
        !passwordValid
    );
  }, [formData, passwordValid, reenterPasswordValid]);

  useEffect(() => {
    if (
      formData.newPassword !== formData.reenterPassword &&
      formData.newPassword.length === formData.reenterPassword.length
    ) {
      setFormErrors({
        ...formErrors,
        reenterPassword: "Passwords must be the same.",
      });
    } else if (
      formData.newPassword.length === formData.reenterPassword.length
    ) {
      setFormErrors({ ...formErrors, reenterPassword: "" });
    }
  }, [formData.newPassword, formData.reenterPassword]);

  const onFormData = (e) => {
    if (e.target.name === "newPassword") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setPasswordValid(
        !reqArray
          .map((req) => req.validator(e.target.value))
          .some((val) => val === false)
      );
      setReenterPasswordValid(e.target.value === formData.reenterPassword);
    } else if (e.target.name === "reenterPassword") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setReenterPasswordValid(e.target.value === formData.newPassword);
    } else {
      reenterArray[0].validator();
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <FISWrapper>
      <h4 className={styles.middle_heading}>{t("Change password")} </h4>

      <form
        style={{ width: "100%", marginTop: "20px" }}
        noValidate
        id="migrationPwdChng"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {globalAlert.message && (
          <FisAlert message={globalAlert.message} type={globalAlert.type} />
        )}
        <FormControl
          variant="outlined"
          style={{
            width: "100%",
            marginTop: "10px",
          }}
        >
          <InputLabel htmlFor="oldPassword" aria-label="Old Password Required">
            {t("Old Password")}
          </InputLabel>
          <OutlinedInput
            className={styles.passwordInput}
            id="oldPassword"
            name="oldPassword"
            // className={`${classes.outlined_inputs} ${classes.input_disabled_style}`}
            onChange={onFormData}
            placeholder={t("Enter your old password")}
            fullWidth
            inputProps={{ maxLength: 15 }}
            autoComplete="off"
            type={passwordToggle.oldPassword ? "password" : "text"}
            value={formData.oldPassword}
            endAdornment={
              <InputAdornment
                position="end"
                style={{
                  marginLeft: "0",
                  marginRight: "-7px",
                  maxWidth: "35px",
                }}
                tabIndex="0"
              >
                <IconButton
                  onClick={(e) => passwordIconHandler("oldPassword", e)}
                  tabIndex="0"
                  data-testid="oldPasswordIcon"
                >
                  {/* <img
                        alt="show hide icon"
                        src={showHideIcon.oldPassword}
                        style={{ marginTop: '2px' }}
                      /> */}
                  <showHideIcon.oldPassword />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Tooltip
          disableHoverListener
          placement={"bottom"}
          leaveTouchDelay={250}
          enterTouchDelay={50}
          leaveDelay={250}
          TransitionComponent={Zoom}
          arrow
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
          title={
            <>
              <Requirements
                value={formData.newPassword || ""}
                requirements={reqArray}
                onValidChange={(isValid) => {}}
              />
              {
                <div
                  // className={isValid ? 'valid' : 'invalid'}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      marginTop: "30",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginLeft: "8px",
                    }}
                  >
                    {/* <InfoOutlinedIcon
                        style={{
                          width: '1em',
                          height: '1em',
                          color: '#f5821f',
                          // marginLeft: '8px',
                        }}
                      /> */}
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={warningIconInfo}
                    ></img>
                  </div>
                  {<>&nbsp;&nbsp;</>}
                  <div
                    style={{
                      // color: 'white',
                      letterSpacing: "0.2px",
                      fontSize: "12px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                    }}
                  >
                    {t("**Note: Password should not match last 10 passwords.")}
                  </div>
                </div>
              }
            </>
          }
        >
          <FormControl
            variant="outlined"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
          >
            <InputLabel
              htmlFor="newPassword"
              aria-label="New Password Required"
            >
              {t("New Password")}
            </InputLabel>
            <OutlinedInput
              id="newPassword"
              name="newPassword"
              className={styles.passwordInput}
              // className={`${classes.outlined_inputs} ${classes.input_disabled_style}`}
              onChange={onFormData}
              placeholder={t("Enter your new password")}
              fullWidth="newPassword"
              data-testid="newPassword"
              inputProps={{ maxLength: 15 }}
              autoComplete="off"
              type={passwordToggle.newPassword ? "password" : "text"}
              value={formData.newPassword}
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{
                    marginLeft: "0",
                    marginRight: "-7px",
                    maxWidth: "35px",
                  }}
                  tabIndex="0"
                >
                  <IconButton
                    onClick={(e) => passwordIconHandler("newPassword", e)}
                    tabIndex="0"
                    data-testid="hidePasswordIcon"
                  >
                    {/* <img
                        alt="show hide icon"
                        src={showHideIcon.newPassword}
                        style={{ marginTop: '2px' }}
                      /> */}
                    <showHideIcon.newPassword />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Tooltip>
        <span
          style={{
            font: "normal normal normal 14px/16px Arial",
            letterSpacing: "0.1px",
            color: "#DF0000",
            opacity: 1,
          }}
          role="alert"
        >
          {t(formErrors.newPassword)}
        </span>
        <Tooltip
          disableHoverListener
          placement={"bottom"}
          leaveTouchDelay={250}
          enterTouchDelay={50}
          leaveDelay={250}
          TransitionComponent={Zoom}
          arrow
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
          title={
            <Requirements
              value={formData.reenterPassword || ""}
              requirements={reenterArray}
              onValidChange={(isValid) => {}}
            />
          }
        >
          <FormControl
            variant="outlined"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
          >
            <InputLabel
              htmlFor="reenterPassword"
              aria-label="Confirm Password Required"
            >
              {t("Confirm Password")}
            </InputLabel>
            <OutlinedInput
              id="reenterPassword"
              name="reenterPassword"
              className={styles.passwordInput}
              // className={`${classes.outlined_inputs} ${classes.input_disabled_style}`}
              onChange={onFormData}
              placeholder={t("Re-Enter your new password")}
              fullWidth
              inputProps={{ maxLength: 15 }}
              autoComplete="off"
              type={passwordToggle.reenterPassword ? "password" : "text"}
              value={formData.reenterPassword}
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{
                    marginLeft: "0",
                    marginRight: "-7px",
                    maxWidth: "35px",
                  }}
                  tabIndex="0"
                >
                  <IconButton
                    onClick={(e) => passwordIconHandler("reenterPassword", e)}
                    tabIndex="0"
                    data-testid="reenterPasswordIcon"
                  >
                    {/* <img
                        alt="show hide icon"
                        src={showHideIcon.reenterPassword}
                        style={{ marginTop: '2px' }}
                      /> */}
                    <showHideIcon.reenterPassword />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Tooltip>
        <span
          style={{
            font: "normal normal normal 14px/16px Arial",
            letterSpacing: "0.1px",
            color: "#DF0000",
            opacity: 1,
          }}
          role="alert"
        >
          {t(formErrors.reenterPassword)}
        </span>
        <Grid container spacing={2} className={styles.btn_wrapper_pass}>
          {/* <Grid item sm={6}>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => changeStep('fistroubleotpchoice')}
              >
                {t('Back')}
              </Button>
            </Grid> */}
          {/* <Grid item sm={6}> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            onClick={submit}
            disabled={submitDisabled}
            className={styles.btn_continue_terms}
          >
            {t("Next")}
          </Button>
          {/* </Grid> */}
        </Grid>
      </form>
      <Box className={styles.link_wrapper}>
        <Link
          component="button"
          variant="body2"
          color="primary"
          onClick={() => {
            changeStep("fislogin");
          }}
          style={{ textDecoration: "underline" }}
        >
          {t("Log in with a different User ID")}
        </Link>
      </Box>
      <LoaderFis open={loading} />
    </FISWrapper>
  );
};

export default MigrationChangePassword;
