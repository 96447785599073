import React, { useEffect, useState } from "react";
import { FISWrapper } from "./FISWrapper";
import styles from "./Enrollment.module.scss";
import {
  Box,
  OutlinedInput,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Link,
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

import { useTranslation } from "../contexts/Localization";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useGlobalUserData } from "../lib/globalUserData";
import { getMessage } from "../utils/status";
import { axiosInstance } from "../services/customaxios";
import LoaderFis from "../components/Loader/LoaderFIS";
import FisAlert from "./FisAlert";

const AdditionalVerification = () => {
  const { changeStep, userData, updateUserData, globalAlert, showGlobalAlert } =
    useGlobalUserData();
  const { t, apiLanguage } = useTranslation();

  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigateBack = () => {
    showGlobalAlert({ type: "", message: "" });
    changeStep("fisloginotpchoice");
  };
  const showHidePasswordHandler = (e) => {
    e.preventDefault();
    setShowPassword(showPassword ? false : true);
  };

  useEffect(() => {
    setSubmitDisabled(
      !formData.userpassword ||
        !formData.securitycode ||
        Object.values(formErrors).some((value) => value !== "")
    );
  }, [formData, formErrors]);

  useEffect(() => {
    showGlobalAlert({ type: "", message: "" });
    return () => {
      if (globalAlert.message === "Your security code was sent again") {
        showGlobalAlert({ type: "", message: "" });
      }
    };
  }, []);

  const onFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case "securitycode":
        if (e.target.value === "") {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please provide a valid One time passcode",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "",
          });
        }
        break;
      case "userpassword":
      default:
        if (e.target.value === "") {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please Enter Password",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "",
          });
        }
        break;
    }
  };

  const resendVerification = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/sendOtp`,
        {
          loginName: userData.userid,
          selectedOtpDevices: [
            {
              id: userData.otpchoice,
            },
          ],
        },
        {
          withCredentials: "include",
          headers: {
            appid: "idm",
            language: apiLanguage,
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setFormData({ securitycode: "", userpassword: "" });
        if (res.data.status.code !== 200) {
          showGlobalAlert({
            type: "error",
            message: getMessage(res.data.status.code).body,
          });
          changeStep("fislogin");
        } else {
          showGlobalAlert({
            type: "success",
            message: "Your security code was sent again",
          });
        }
      })
      .catch((err) => {
        setFormData({ securitycode: "", userpassword: "" });
        updateUserData({
          ...userData,
          userpassword: "",
          password: "",
        });
        setLoading(false);
        console.error(err);
        showGlobalAlert({
          type: "error",
          message: getMessage("defaultCode").body,
        });
      });
  };

  const submitVerification = () => {
    setShowPassword(false);
    const passwordPayLoad = {
      step: "password",
      data: {
        loginName: userData.userid,
        browserFootprint:
          "557ce527b9b389cd30796fc85cb5f8e26f3ef0673cc6737f69cabef5851cbc0e",
        selectedDeviceId: userData.otpchoice,
        otpPin: formData.securitycode,
        password: formData.userpassword,
      },
    };
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/password`,
        passwordPayLoad,
        {
          withCredentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: "idm",
            language: apiLanguage,
          },
        }
      )
      .then((resp) => {
        if (
          resp.data.result[0].nextStep &&
          resp.data.result[0].nextStep.id === "changepassword"
        ) {
          updateUserData({
            ...userData,
            cookie: resp.data.result[0].cookies[0],
          });
          changeStep("migrationchangepassword");
        } else if (
          resp.data.result[0].nextStep &&
          resp.data.result[0].nextStep.id === "policy"
        ) {
          updateUserData({
            ...userData,
            cookie: resp.data.result[0].cookies[0],
            policy: {
              pageTitle: resp.data.result[0].nextStep.params.pageTitle,
              policyText: resp.data.result[0].nextStep.params.policyText,
              signature: resp.data.result[0].nextStep.params.signature,
              date: resp.data.result[0].nextStep.params.date,
            },
          });
          changeStep("fisterms");
        } else if (
          resp.data.result[0].nextStep &&
          resp.data.result[0].nextStep.id === "username"
        ) {
          if (resp.data.status.code === 302) {
            showGlobalAlert({
              type: "error",
              message:
                "Your One Time Passcode is invalid. Please check and try again. Your login credentials will be locked after 3 wrong attempts consecutively.",
            });
          } else {
            const { body } = getMessage(resp.data.status.code);
            showGlobalAlert({ type: "error", message: body });
          }
          changeStep("fisloginusername");
        } else {
          const { body } = getMessage(resp.data.status.code);
          if (resp.data.status.code === 504 || resp.data.status.code === 302) {
            setLoading(false);
            // alertOpen();
            // setAlertMessage({ title: t(title), body: t(body) });
            showGlobalAlert({
              type: "error",
              message: body,
            });
            setFormData({ securitycode: "", userpassword: "" });
          } else if (resp.data.status.code !== 200) {
            showGlobalAlert({
              type: "error",
              message: body,
            });
            changeStep("fislogin");
          } else {
            console.log("this will never run..................");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        updateUserData({
          ...userData,
          userpassword: "",
          password: "",
        });
        showGlobalAlert({
          type: "error",
          message: getMessage("defaultCode").body,
        });
      });
  };

  return (
    <FISWrapper>
      <Box
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          marginLeft: "-3rem",
        }}
      >
        <div>
          <ArrowBackIcon
            onClick={navigateBack}
            data-testid="backBtnAdditionalVerification"
          />
        </div>
        <Box className={styles.heading_verification} id="stylesHeading">
          {t("Additional Verification Required")}
        </Box>
      </Box>
      <Box style={{ margin: "16px 0 0px 0px" }}>
        {t("Please check your device for one-time security code")}
      </Box>
      <form
        style={{ width: "100%", marginTop: "20px" }}
        noValidate
        id="additionalverificationpage"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {globalAlert.message && (
          <FisAlert message={globalAlert.message} type={globalAlert.type} />
        )}
        <Box className={styles.boxMarginTop}>
          <FormControl
            style={{ width: "100%" }}
            // error={formErrors && formErrors.userpassword}
            variant="outlined"
          >
            <InputLabel htmlFor="component-outlined">
              {t("Password")}
            </InputLabel>
            <OutlinedInput
              id="userpassword"
              autoFocus
              name="userpassword"
              onChange={onFormData}
              placeholder={t("Enter Password")}
              fullWidth
              inputProps={{ maxLength: 50 }}
              autoComplete="off"
              type={!showPassword ? "password" : "text"}
              value={formData.userpassword}
              style={{ borderRight: "none" }}
              className={styles.passwordInput}
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{
                    marginLeft: "0",
                    marginRight: "-7px",
                    maxWidth: "35px",
                  }}
                  tabIndex="0"
                  // onBlur={pwdIconBlur}
                >
                  <IconButton
                    onClick={showHidePasswordHandler}
                    tabIndex="0"
                    data-testid="showHidePasswordHandler"
                  >
                    {showPassword === true ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box className={styles.boxMarginTop}>
          <FormControl
            style={{ width: "100%" }}
            // error={formErrors && formErrors.userpassword}
            variant="outlined"
          >
            <InputLabel htmlFor="component-outlined">
              {t("Security Code")}
            </InputLabel>
            <OutlinedInput
              id="securitycode"
              name="securitycode"
              onChange={onFormData}
              placeholder={t("Enter Security Code")}
              fullWidth
              inputProps={{ maxLength: 6, inputMode: "numeric" }}
              autoComplete="off"
              type={"text"}
              value={formData.securitycode}
              style={{ borderRight: "none" }}
              className={styles.passwordInput}
            />
          </FormControl>
        </Box>
        <Box style={{ marginTop: "10px" }}>
          <Link
            component="button"
            variant="body2"
            color="primary"
            type="button"
            onClick={resendVerification}
            style={{ textDecoration: "underline" }}
          >
            {t("Resend security code")}
          </Link>
        </Box>
        <Box className={styles.btn_wrapper}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={submitDisabled}
            onClick={submitVerification}
            style={{ width: "386px" }}
            className={styles.btn_continue_terms}
          >
            {t("Continue")}
          </Button>
        </Box>
      </form>
      <LoaderFis open={loading} />
    </FISWrapper>
  );
};

export default AdditionalVerification;
