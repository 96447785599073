import React, { useEffect } from "react";
import { useGlobalUserData } from "../lib/globalUserData";

const NewComponent = () => {
  const { changeStep, setLogoutbtn } = useGlobalUserData();
  useEffect(() => {
    setTimeout(() => {
      changeStep("fislogin");
      setLogoutbtn(false);
    }, 900000);
  }, []);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <h1>Welcome to the new portal!</h1>
    </div>
  );
};

export default NewComponent;
