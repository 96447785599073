import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../services/customaxios';
import LoaderFis from '../components/Loader/LoaderFIS';
import { useGlobalUserData } from '../lib/globalUserData';
import { useTranslation } from '../contexts/Localization';
import { getURLParameters } from '../utils/getUrlParameters';
import { getMessage } from '../utils/status';
import { formatDevices } from '../utils/formatDevices';
import { Box, Button } from '@material-ui/core';
import styles from './Enrollment.module.scss';
import { FISWrapper } from './FISWrapper';
import warningIconVerification from '../images/warningIconVerification.svg';

const TempPassword = () => {
  const { userData, changeStep, updateUserData, showGlobalAlert } =
    useGlobalUserData();
  const { t, apiLanguage } = useTranslation();
  const [loading, setLoading] = useState(false);
  const paramsObj = getURLParameters(
    new URLSearchParams(window.location.search).toString()
  );
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/batch/passwordreset-status-check`,
        {
          token: paramsObj.token,
        },
        {
          withCredentials: 'include',
          headers: {
            appid: 'idm',
            language: apiLanguage,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((res) => {
        if (res.data.code !== 200) {
          setLoading(false);
          showGlobalAlert({
            type: 'error',
            message: getMessage(res.data.code).body,
          });
          changeStep('fislogin');
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        changeStep('fislogin');
        updateUserData({
          ...userData,
          password: '',
          userpassword: '',
        });
        console.error(err);
        setLoading(false);
        showGlobalAlert({
          type: 'error',
          message: getMessage('defaultCode').body,
        });
      });
  }, []);
  const sendEmailApi = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/batch/sendTempPassword`,
        {
          token: paramsObj.token,
        },
        {
          withCredentials: 'include',
          headers: {
            appid: 'idm',
            language: apiLanguage,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((res) => {
        if (res.data.status.code !== 200) {
          setLoading(false);
          showGlobalAlert({
            type: 'error',
            message: getMessage(res.data.status.code).body,
          });
          changeStep('fislogin');
        } else {
          setLoading(false);
          updateUserData({
            ...userData,
            otpDestinations: res.data.result[0].nextStep.params.devices,
            otpDeviceList: formatDevices(
              res.data.result[0].nextStep.params.devices
            ),
            userid: res.data.result[0].nextStep.params.loginName,
            migrationInfo: { migrationType: 'BATCH' },
            flow: 'BATCH_AUTH',
          });
          changeStep('fisloginotpchoice');
        }
      })
      .catch((err) => {
        changeStep('fislogin');
        updateUserData({
          ...userData,
          password: '',
          userpassword: '',
        });
        setLoading(false);
        console.error(err);
        showGlobalAlert({
          type: 'error',
          message: getMessage('defaultCode').body,
        });
      });
  };

  return (
    <FISWrapper>
      <img
        style={{ width: '60px', height: '60px' }}
        src={warningIconVerification}
      />
      <Box
        className={styles.heading_temppassword}
        style={{ marginTop: '20px' }}
      >
        {t('Password Reset Required')}
      </Box>
      <Box className={styles.subHeading} style={{ marginTop: '20px' }}>
        {t(
          "A password reset is required to access your accounts. Please click on 'Reset password' below to create a new password."
        )}
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        style={{ width: '220px', marginTop: '45px' }}
        justifyContent={'center'}
      >
        <Button
          variant="text"
          type="submit"
          // fullWidth
          className={styles.login_button}
          style={{ fontSize: '14px' }}
          onClick={sendEmailApi}
        >
          {t('Reset Password')}
        </Button>
      </Box>
      <LoaderFis open={loading} />
    </FISWrapper>
  );
};

export default TempPassword;
