/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { axiosInstance } from "../services/customaxios";
import {
  Grid,
  Box,
  OutlinedInput,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Link,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import calendarIcon from "../images/calendarIcon.svg";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

import styles from "./Enrollment.module.scss";
import { useGlobalUserData } from "../lib/globalUserData";
import { useTranslation } from "../contexts/Localization";
import { FISWrapper } from "./FISWrapper";
import { AccessAlarm, AddIcCallOutlined } from "@material-ui/icons";
import moment from "moment/moment";
import FisAlert from "./FisAlert";
import LoaderFis from "../components/Loader/LoaderFIS";
import { getEnrollMessage } from "./enrollStatus";
import { DatePicker } from "@mui/x-date-pickers";

const Enroll = () => {
  const { t } = useTranslation();
  const { changeStep, globalAlert, showGlobalAlert, updateUserData, userData } =
    useGlobalUserData();
  const [formData, setFormData] = useState({ accountType: "empty" });
  const [formErrors, setFormErrors] = useState({ socialSecurityNumber: "" });
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const isDateValid = (...val) => !Number.isNaN(new Date(...val).valueOf());
  function getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
    const age = getAge(date);
    if (!isDateValid(date) || !date) {
      setFormErrors({
        ...formErrors,
        dob: "Please enter Date of Birth",
      });
    } else if (age < 0 || age > 150) {
      setFormErrors({
        ...formErrors,
        dob: "Please enter valid Date Of Birth",
      });
    } else if (age < 13) {
      setFormErrors({
        ...formErrors,
        dob: "The minimum age for enrollment is 13 years",
      });
    } else {
      setFormErrors({
        ...formErrors,
        dob: "",
      });
    }
  };
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const color = "#c44242";

  const theme = createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            // color
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "0",
            // color,
            color: "black",
            // height: '28px'
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: 300,
            letterSpacing: "0.1px",
            // color: textColor,
            marginTop: "15px",
            marginBottom: "4px",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            borderRadius: "0",
            color: "black",
            // height: '28px'
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: 300,
            letterSpacing: "0.1px",
            // color: textColor,
            marginTop: "15px",
            marginBottom: "4px",
          },
        },
      },
    },
  });

  useEffect(() => {
    showGlobalAlert({ type: "", message: "" });
    return () => {
      showGlobalAlert({ type: "", message: "" });
    };
  }, []);
  useEffect(() => {
    if (globalAlert.message) {
      setShowCustomAlert(false);
    }
  }, [globalAlert]);
  const checkErrors = () =>
    Object.values(formErrors).some((value) => value !== "");

  useEffect(() => {
    // console.log('isFormDataValid()');
    setSubmitDisabled(
      !formData.socialSecurityNumber ||
        formData.accountType === "empty" ||
        !formData.accountType ||
        !formData.accountNumber ||
        !selectedDate ||
        checkErrors()
    );
    console.log("formErrors................................");
    console.table(formErrors);
    console.table(formData);
    console.log(selectedDate);
    // setSubmitDisabled(
    //   !formData.userpassword ||
    //     !formData.securitycode ||
    //     Object.values(formErrors).some((value) => value !== '')
    // );
  }, [formData, formErrors, selectedDate]);

  // const validateUser2 = () => {
  //   console.log('____________________validateuser____________________');
  // }
  const validateUser = () => {
    setLoading(true);
    const payload = {
      dob: moment(selectedDate).format("YYYY-MM-DD"),
      accountType: formData.accountType,
      ssn: formData.socialSecurityNumber,
      accountNumber: formData.accountNumber,
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}enrollments/validateUser`,
        {
          ...payload,
        },
        {
          headers: {
            appid: "idm",
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_ENROLLMENT,
          },
        }
      )
      .then((res) => {
        const {
          status: { code },
          id,
        } = res.data;
        updateUserData({ ...userData, ...payload, sessionId: id });
        if (code === 301) {
          setShowCustomAlert(true);
          showGlobalAlert({
            type: "",
            message: "",
          });
        } else if (code !== 200) {
          showGlobalAlert({
            type: "error",
            message: getEnrollMessage(code).body,
          });
        } else {
          changeStep("enrollmentcredentials");
        }
      })
      .catch((err) => {
        updateUserData({ ...userData, ...payload });
        showGlobalAlert({
          type: "error",
          message: getEnrollMessage("defaultCode").body,
        });
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFormData = (e) => {
    switch (e.target.name) {
      case "socialSecurityNumber":
        if (e.target.value === "") {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please enter Social Security Number",
          });
        } else if (!e.target.value.match(/^\d*$/)) {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value.replace(/\D/, ""),
          });
          // setFormErrors({
          //   ...formErrors,
          //   [e.target.name]: 'Social security number must be at least 9 digits',
          // });
        } else if (e.target.value.length < 9) {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please enter valid Social Security Number",
          });
        } else {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          setFormErrors({
            ...formErrors,
            [e.target.name]: "",
          });
        }
        break;
      case "accountType":
        if (e.target.value === "empty") {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please select Account Type",
          });
        } else {
          getAge();
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          setFormErrors({
            ...formErrors,
            [e.target.name]: "",
          });
        }
        break;
      case "accountNumber":
      default:
        if (e.target.value === "") {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please enter Account Number",
          });
        } else if (!e.target.value.match(/^\d*$/)) {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value.replace(/\D/, ""),
          });
        } else if (e.target.value.length < 2) {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please enter valid Account Number",
          });
        } else {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          setFormErrors({
            ...formErrors,
            [e.target.name]: "",
          });
        }
        break;
    }
  };

  const hiddenTestSubmit = () => {
    checkErrors();
    validateUser();
    handleDateChange(null);
    handleDateChange(new Date());
    handleDateChange(new Date("11-11-1999"));
    handleDateChange(new Date("11-11-2013"));
    handleDateChange(new Date("11-11-1857"));
    handleDateChange(new Date("11-11-2029"));
  };
  return (
    <FISWrapper>
      <Box className={styles.heading}>{t("Verify Your Identity")}</Box>
      <Box className={styles.subHeading}>
        {t("Let's start by verifying who you are:")}
      </Box>
      <form
        style={{ width: "100%", marginTop: "20px" }}
        noValidate
        id="enrollPage"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {globalAlert.message && (
          <FisAlert message={globalAlert.message} type={globalAlert.type} />
        )}
        {showCustomAlert && (
          <Box id="alertMessage" className={styles.successAlert}>
            <InfoOutlinedIcon
              fontSize="small"
              style={{ marginRight: "10px" }}
            />
            <span
              style={{
                letterSpacing: "normal",
                fontSize: "0.9rem",
              }}
            >
              <>{t("You are already registered for online banking. Please")} </>
              <Link
                component="button"
                variant="body2"
                color="#e37617"
                onClick={() => {
                  changeStep("fislogin");
                }}
                style={{
                  textDecoration: "underline",
                  color: "#e37617",
                  verticalAlign: "unset",
                }}
              >
                {t("Sign In.")}
              </Link>
            </span>
          </Box>
        )}
        <Box className={styles.boxMarginTop}>
          {/* <KeyboardDatePicker
              disableToolbar
              fullWidth
              className={styles.datepicker}
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date of Birth"
              value={selectedDate || null}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              autoOk={true}
              autoFocus
            /> */}
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label=""
                // aria-labelledby=""
                sx={{ width: "100%", borderRadius: "0" }}
                data-testid="date-of-birth"
                minDate={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 150)
                  )
                }
                // popper
                PopperProps={{
                  sx: {
                    "&.MuiPickersPopper-root": {
                      border: "4px solid red",
                    },
                  },
                }}
                fullWidth
                error={false}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    placeholder: t("Date of Birth"),
                    error: formErrors.dob,
                    // className: styles.passwordInput
                  },
                }}
                // textField:
                slots={{
                  // textField: () => <TextField />,
                  openPickerIcon: () => <img src={calendarIcon} />,
                }}
              />
            </LocalizationProvider>
          </ThemeProvider>
          {formErrors.dob && (
            <Box className={styles.inlineAlert}>
              <InfoOutlinedIcon fontSize="small" />
              {t(formErrors.dob)}
            </Box>
          )}
        </Box>
        <FormControl
          style={{
            width: "100%",
            backgroundColor: "",
            marginTop: 15,
          }}
          error={formErrors && formErrors.socialSecurityNumber}
          variant="outlined"
        >
          <InputLabel htmlFor="component-outlined">
            {t("Social Security Number")}
          </InputLabel>
          <OutlinedInput
            id="socialSecurityNumber"
            name="socialSecurityNumber"
            error={formErrors && formErrors.socialSecurityNumber}
            onChange={onFormData}
            placeholder={t("Social Security Number")}
            fullWidth
            inputProps={{ maxLength: 9, inputMode: "numeric" }}
            autoComplete="off"
            value={formData.socialSecurityNumber}
            className={styles.passwordInput}
          />
        </FormControl>
        {formErrors.socialSecurityNumber && (
          <Box className={styles.inlineAlert}>
            <InfoOutlinedIcon fontSize="small" />
            {t(formErrors.socialSecurityNumber)}
          </Box>
        )}
        <Box className={styles.boxMarginTop} style={{ marginTop: "20px" }}>
          <Box
            fullWidth
            className={styles.customInput}
            style={{ height: "50px", marginTop: "10px" }}
          >
            <Select
              id="accountType"
              name="accountType"
              label="Account Type"
              labelId="accountTypeLabel"
              value={formData.accountType || "empty"}
              onChange={onFormData}
              variant="outlined"
              fullWidth
              className={styles.customInput}
              input={<OutlinedInput />}
              error={formErrors && formErrors.accountType}
            >
              <MenuItem role="option" value="empty">
                {t("Account Type")}
              </MenuItem>
              <MenuItem role="option" value={"Checking"}>
                {t("Checking")}
              </MenuItem>
              <MenuItem role="option" value={"Savings"}>
                {t("Savings")}
              </MenuItem>
              <MenuItem role="option" value={"Loan"}>
                {t("Loan")}
              </MenuItem>
              <MenuItem role="option" value={"CD or IRA"}>
                {t("CD or IRA")}
              </MenuItem>
            </Select>
          </Box>
          {formErrors.accountType && (
            <Box className={styles.inlineAlert}>
              <InfoOutlinedIcon fontSize="small" />
              {t(formErrors.accountType)}
            </Box>
          )}
        </Box>
        <Box className={styles.boxMarginTop}>
          <>
            <FormControl
              style={{
                width: "100%",
                backgroundColor: "",
                marginTop: 10,
              }}
              error={formErrors && formErrors.accountNumber}
              variant="outlined"
            >
              <InputLabel htmlFor="component-outlined">
                {t("Account Number")}
              </InputLabel>
              <OutlinedInput
                id="accountNumber"
                name="accountNumber"
                error={formErrors && formErrors.accountNumber}
                onChange={onFormData}
                placeholder={t("Account Number")}
                fullWidth
                inputProps={{ maxLength: 16, inputMode: "numeric" }}
                autoComplete="off"
                value={formData.accountNumber}
                className={styles.passwordInput}
              />
            </FormControl>
            {formErrors.accountNumber && (
              <Box className={styles.inlineAlert}>
                <InfoOutlinedIcon fontSize="small" />
                {t(formErrors.accountNumber)}
              </Box>
            )}
          </>
        </Box>
        <Grid
          container
          spacing={2}
          style={{ marginTop: 20 }}
          className={styles.btn_wrapper_pass}
          justifyContent="space-around"
        >
          <Grid item sm={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              // className={styles.submit_password}
              className={styles.btn_continue_terms}
              disabled={submitDisabled}
              onClick={validateUser}
            >
              {t("Continue")}
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() => changeStep("fislogin")}
              // className={styles.cancel_btn}
            >
              {t("Cancel")}
            </Button>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          data-testid="hidden-submit"
          style={{ display: "none" }}
          // className={styles.submit_password}
          // disabled={submitDisabled}
          onClick={hiddenTestSubmit}
        >
          {t("HiddenTestSubmit")}
        </Button>
      </form>
      <LoaderFis open={loading} />
    </FISWrapper>
  );
};

export default Enroll;
