/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  OutlinedInput,
  Button,
  InputAdornment,
  IconButton,
  Tooltip,
  Zoom,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

import styles from "./Enrollment.module.scss";
import { useGlobalUserData } from "../lib/globalUserData";
import hideIcon from "../images/eyeIconHide.png";
import showIcon from "../images/eyeIcon.png";
import { enrollTooltipStyles } from "./EnrollTooltipStyles";
import { Requirements } from "../components/Requirements/Requirements";
import { useTranslation } from "../contexts/Localization";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LoaderFis from "../components/Loader/LoaderFIS";
import { axiosInstance } from "../services/customaxios";
import { getEnrollMessage } from "./enrollStatus";
import FisAlert from "./FisAlert";
import { FISWrapper } from "./FISWrapper";
import warningIconInfo from "../images/warningIconInfo.svg";
import { getReqArray } from "../utils/requirementArray";

const useStyles = enrollTooltipStyles();

const EnrollPassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { changeStep, userData, showGlobalAlert, globalAlert } =
    useGlobalUserData();
  const [formData, setFormData] = useState({ accountType: "empty" });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showHideIcon, setShowHideIcon] = useState({
    password: VisibilityOffOutlinedIcon,
    reenterPassword: VisibilityOffOutlinedIcon,
  });

  const [passwordValid, setPasswordValid] = useState(false);
  const [reenterPasswordValid, setReenterPasswordValid] = useState(false);
  const [passwordToggle, setPasswordToggle] = useState({
    password: true,
    reenterPassword: true,
  });

  const reqArray = getReqArray(userData.userid);

  const reenterArray = [
    {
      validator: (val) => val === formData.password,
      text: "Passwords must be the same.",
      reqSatisfied: false,
    },
  ];
  useEffect(() => {
    return () => {
      showGlobalAlert({ type: "", message: "" });
    };
  }, []);

  const onFormData = (e) => {
    if (e.target.name === "password") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setPasswordValid(
        !reqArray
          .map((req) => req.validator(e.target.value))
          .some((val) => val === false)
      );
      setReenterPasswordValid(e.target.value === formData.reenterPassword);
      reenterArray[0].validator();
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setReenterPasswordValid(e.target.value === formData.password);
    }
  };

  const passwordIconHandler = (passname, e) => {
    e.preventDefault();
    setPasswordToggle({
      ...passwordToggle,
      [passname]: !passwordToggle[passname],
    });
    const icon =
      showHideIcon[passname] === VisibilityOffOutlinedIcon
        ? VisibilityOutlinedIcon
        : VisibilityOffOutlinedIcon;
    setShowHideIcon({
      ...showHideIcon,
      [passname]: icon,
    });
  };
  const enrollmentSubmit = () => {
    setLoading(true);
    setPasswordToggle({
      password: true,
      reenterPassword: true,
    });
    setShowHideIcon({
      password: VisibilityOffOutlinedIcon,
      reenterPassword: VisibilityOffOutlinedIcon,
    });
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}enrollments/enroll`,
        {
          sessionId: userData.sessionId,
          password: formData.password,
          username: userData.userid,
        },
        {
          headers: {
            appid: "idm",
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_ENROLLMENT,
          },
        }
      )
      .then((res) => {
        console.log("response");
        console.log(res);
        const code = res.data.status.code;
        if (code !== 200) {
          showGlobalAlert({
            type: "error",
            message: getEnrollMessage(code).body,
          });
        } else {
          changeStep("enrollmentsuccess");
        }
      })
      .catch((err) => {
        showGlobalAlert({
          type: "error",
          message: getEnrollMessage("defaultCode").body,
        });
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FISWrapper>
      <>
        <Box className={styles.heading}>{t("Create Your Password")}</Box>
        <Box className={styles.subHeading} style={{ textAlign: "center" }}>
          {t(
            "Please enter a new password to create your account. Re-enter your password to continue."
          )}
        </Box>
        <form
          style={{ width: "100%", marginTop: "20px" }}
          noValidate
          id="enrollPwd"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {globalAlert.message && (
            <FisAlert message={globalAlert.message} type={globalAlert.type} />
          )}
          <Box className={styles.boxMarginTop}>
            <Tooltip
              placement={"bottom"}
              leaveTouchDelay={250}
              enterTouchDelay={50}
              leaveDelay={250}
              TransitionComponent={Zoom}
              arrow
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
              }}
              title={
                <Requirements
                  data-testid="enterRequirement"
                  value={formData.password || ""}
                  requirements={reqArray}
                  onValidChange={(isValid) => {
                    //  setPasswordValid(isValid)
                  }}
                />
              }
            >
              <FormControl
                variant="outlined"
                style={{
                  width: "100%",
                }}
              >
                <InputLabel htmlFor="component-outlined">
                  {t("Choose your password")}
                </InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  autoFocus
                  onChange={onFormData}
                  placeholder={t("Choose your password")}
                  fullWidth
                  inputProps={{ maxLength: 15 }}
                  autoComplete="off"
                  value={formData.password}
                  type={passwordToggle.password ? "password" : "text"}
                  className={styles.passwordInput}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{
                        marginLeft: "0",
                        marginRight: "-7px",
                        maxWidth: "35px",
                      }}
                      tabIndex="0"
                    >
                      <IconButton
                        data-testid="showHidePasswordHandler"
                        onClick={(e) => passwordIconHandler("password", e)}
                        tabIndex="0"
                      >
                        <showHideIcon.password />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Tooltip>
          </Box>
          <Box className={styles.boxMarginTop}>
            <Tooltip
              placement={"bottom"}
              leaveTouchDelay={250}
              enterTouchDelay={50}
              leaveDelay={250}
              TransitionComponent={Zoom}
              arrow
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
              }}
              title={
                <Requirements
                  data-testid="reenterRequirement"
                  value={formData.reenterPassword || ""}
                  requirements={reenterArray}
                  onValidChange={(isValid) => {
                    // setReenterPasswordValid(isValid)
                  }}
                />
              }
            >
              <FormControl
                variant="outlined"
                style={{
                  width: "100%",
                }}
              >
                <InputLabel htmlFor="component-outlined">
                  {t("Enter confirm password")}
                </InputLabel>
                <OutlinedInput
                  id="reenterPassword"
                  name="reenterPassword"
                  onChange={onFormData}
                  placeholder={t("Enter confirm password")}
                  fullWidth
                  inputProps={{ maxLength: 15 }}
                  autoComplete="off"
                  value={formData.reenterPassword}
                  type={passwordToggle.reenterPassword ? "password" : "text"}
                  className={styles.passwordInput}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{
                        marginLeft: "0",
                        marginRight: "-7px",
                        maxWidth: "35px",
                      }}
                      tabIndex="0"
                    >
                      <IconButton
                        data-testid="showHidereenterPasswordHandler"
                        onClick={(e) =>
                          passwordIconHandler("reenterPassword", e)
                        }
                        tabIndex="0"
                      >
                        <showHideIcon.reenterPassword />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Tooltip>
          </Box>
          {/* <Box className={styles.btn_wrapper} style={{ marginTop: 180 }}>
            <Button
              variant="text"
              className={styles.continue_button}
              onClick={enrollmentSubmit}
              disabled={!passwordValid || !reenterPasswordValid}
            >
              {t('Continue')}
            </Button>
            <Button
              variant="text"
              className={styles.button_cancel}
              onClick={() => changeStep('fislogin')}
            >
              {t('Cancel')}
            </Button>
          </Box> */}
          <Grid
            container
            spacing={2}
            style={{ marginTop: 100 }}
            className={styles.btn_wrapper_pass}
            justifyContent="space-around"
          >
            <Grid item sm={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={styles.btn_continue_terms}
                disabled={!passwordValid || !reenterPasswordValid}
                onClick={enrollmentSubmit}
              >
                {t("Continue")}
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => changeStep("fislogin")}
              >
                {t("Cancel")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
      <LoaderFis open={loading} />
    </FISWrapper>
  );
};

export default EnrollPassword;
