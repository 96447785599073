/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import {
  Button,
  Select,
  MenuItem,
  Box,
  Link,
  OutlinedInput,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { axiosInstance } from "../services/customaxios";
import LoaderFis from "../components/Loader/LoaderFIS";
import { useGlobalUserData } from "../lib/globalUserData";
import { useTranslation } from "../contexts/Localization";
import styles from "./Enrollment.module.scss";
import selectStyles from "./CustomSelect.module.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FISWrapper } from "./FISWrapper";
import phoneIcon from "../images/phoneIcon.svg";
import { getMessage } from "../utils/status";
import FisAlert from "./FisAlert";

const OtpChoiceLogin = () => {
  const { changeStep, userData, updateUserData, showGlobalAlert, globalAlert } =
    useGlobalUserData();
  const { t, apiLanguage } = useTranslation();
  const [formData, setFormData] = useState({ otpchoice: "empty" });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({
    label: "",
    deviceInfo: [],
  });

  useEffect(() => {
    console.log("_______________________________");
    console.log(userData);
    showGlobalAlert({ type: "", message: "" });
    setFormData({
      ...formData,
      ...userData,
      otpchoice: userData.otpDeviceList[0].uid,
    });
    const selectDev = userData.otpDeviceList[0];
    setSelectedDevice(selectDev);
  }, []);

  const getSendOtpText = (label) => {
    switch (label) {
      case "SMS":
      case "MOBILE":
        return (
          <>
            <img src={phoneIcon}></img>&nbsp;&nbsp;{t("Send SMS")}
          </>
        );
      case "VOICE":
        return (
          <>
            <img src={phoneIcon}></img>&nbsp;&nbsp;{t("Send Voice Call")}
          </>
        );
      case "EMAIL":
      default:
        return (
          <>
            <img src={phoneIcon}></img>&nbsp;&nbsp;{t("Send Code to Email")}
          </>
        );
    }
  };

  const submitOtpChoice = (otpchoice) => {
    setLoading(true);
    updateUserData({ ...userData, otpchoice });
    const payLoad = {
      data: {
        loginName: userData.userid,
        browserFootprint:
          "557ce527b9b389cd30796fc85cb5f8e26f3ef0673cc6737f69cabef5851cbc0e",
        selectedDeviceId: otpchoice,
        next: true,
      },
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/otpchoice`,
        payLoad,
        {
          withCredentials: "include",
          headers: {
            appid: "idm",
            language: apiLanguage,
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        updateUserData({ ...userData, otpchoice });
        if (res.data.status.code !== 200) {
          showGlobalAlert({
            type: "error",
            message: getMessage(res.data.status.code).body,
          });
          changeStep("fislogin");
        } else {
          if (userData.flow === "BATCH_AUTH") {
            changeStep("additionalverification");
          } else {
            changeStep("fisloginotp");
          }
        }
      })
      .catch((err) => {
        updateUserData({
          ...userData,
          userpassword: "",
          password: "",
        });
        setLoading(false);
        console.error(err);
        showGlobalAlert({
          type: "error",
          message: getMessage("defaultCode").body,
        });
      });
  };

  const onFormData = (e) => {
    const selectDev = userData.otpDeviceList.find(
      (destn) => destn.uid === e.target.value
    );
    setSelectedDevice(selectDev);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setFormErrors({
      ...formErrors,
      captchaerror: "",
      [e.target.name]: "",
    });
  };

  const navigateBack = () => {
    showGlobalAlert({ type: "", message: "" });
    changeStep("fislogin");
  };
  const hiddenTestButton = () => {
    const dummy = { target: { value: "c13b3199-ef3d-4535-ad7d-e35a9a3208d8" } };
    onFormData(dummy);
  };

  return (
    <>
      <FISWrapper>
        <Box
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            marginLeft: "-3rem",
          }}
        >
          <div>
            <ArrowBackIcon
              onClick={navigateBack}
              data-testid="backButtonOTPChoiceLogin"
            />
          </div>
          <Box className={styles.heading_verification}>
            {t("Additional Verification Required")}
          </Box>
        </Box>
        <Box className={styles.subHeading}>
          {t("Please verify your device to continue.")}
        </Box>
        <form style={{ width: "100%", marginTop: "20px" }} id="otpChoiceLogin">
          {globalAlert.message && (
            <FisAlert message={globalAlert.message} type={globalAlert.type} />
          )}
          <Box className={styles.boxMarginTop} style={{ height: "40px" }}>
            <Select
              className={styles.customInput}
              id="otpchoice"
              name="otpchoice"
              variant="outlined"
              role="listbox"
              value={formData.otpchoice || ""}
              onChange={onFormData}
              fullWidth
              IconComponent={KeyboardArrowDownIcon}
              classes={{
                icon: "select-icon",
                select:
                  formData.otpchoice === ""
                    ? selectStyles.select_inputs_placeholder
                    : selectStyles.select_style,
              }}
              input={<OutlinedInput />}
            >
              <MenuItem role="option" value="empty">
                {t("Select a destination for your One Time Passcode")}
              </MenuItem>
              {userData.otpDeviceList.map(({ label, uid }) => (
                <MenuItem
                  className={selectStyles.otp_menuitem_style}
                  key={uid}
                  role="option"
                  value={uid}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </form>
        <Box
          className={styles.trouble_otpchoice_btn_wrapper}
          style={{ minHeight: "130px" }}
        >
          {selectedDevice?.deviceInfo.length > 0 &&
            selectedDevice?.deviceInfo.map((sendMode) => (
              <>
                <Button
                  type="button"
                  key={sendMode.value}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => submitOtpChoice(sendMode.value)}
                  className={styles.otpchoice_send}
                >
                  {getSendOtpText(sendMode.groupLabel)}
                </Button>
              </>
            ))}
        </Box>
        <Box className={styles.link_wrapper} id="linkBoxWrapper">
          <Link
            component="button"
            id="fislogin"
            color="primary"
            variant="body2"
            onClick={() => {
              changeStep("fislogin");
            }}
            style={{ textDecoration: "underline" }}
          >
            {t("Log in with a different User ID")}
          </Link>
        </Box>
      </FISWrapper>
      <LoaderFis open={loading} />
      {userData.isTesting && (
        <div style={{ display: "none" }}>
          {getSendOtpText("SMS")}
          {getSendOtpText("MOBILE")}
          {getSendOtpText("VOICE")}
          {getSendOtpText("EMAIL")}
          <Button
            style={{ display: "none" }}
            data-testid="hiddenTestButton"
            onClick={hiddenTestButton}
          >
            HiddenTestButton
          </Button>
        </div>
      )}
    </>
  );
};

export default OtpChoiceLogin;
